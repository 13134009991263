<template>
  <div class="data-box">
    <AccountData
      class="withdraw-data-min"
      :statistics="statistics"
      :canBackfillAble="canBackfillAble"
      @backfill="$emit('backfill')"
    />
    <div
      class="data-box-title"
      :style="{
        paddingBottom: `${hide ? 0 : 20}px`,
      }"
    >
      <div class="left">{{ $t("assetbag.desc2") }}</div>
      <div class="right">
        <div class="btn" :class="{ hide }" @click="hide = !hide">
          {{ hide ? $t("assetbag.desc38") : $t("assetbag.desc37") }}
        </div>
      </div>
    </div>
    <div
      class="data-overview"
      :style="{
        height: `${hide ? 0 : domHeight}`,
      }"
      ref="DataDom"
    >
      <div class="data-left">
        <div class="item">
          <div class="name">
            {{ $t("assetbag.desc5", { n: statistics.sellCoinSymbol }) }}
            <tooltip :content="$t('assetbag.desc103')" />
          </div>
          <div class="val">
            {{ common.cutXiaoNum1(statistics.totalInvestAmount) }}
          </div>
        </div>
        <div class="item">
          <div class="name">
            {{ $t("assetbag.desc6") }}
          </div>
          <div class="val">{{ statistics.totalCount }}</div>
        </div>
        <div class="item">
          <div class="name">
            {{ $t("assetbag.desc7", { n: statistics.releaseCoinSymbol }) }}
            <tooltip
              :content="
                $t('assetbag.desc104', { n: statistics.releaseCoinSymbol })
              "
            />
          </div>
          <div class="val">
            {{common.cutXiaoNum1(statistics.totalReleasedAmount) }}/{{
             common.cutXiaoNum1( statistics.totalReleaseAmount)
            }}
          </div>
        </div>
        <div class="item">
          <div class="name">
            {{ $t("assetbag.desc8", { n: statistics.releaseCoinSymbol }) }}
            <tooltip
              :content="
                $t('assetbag.desc105', { n: statistics.releaseCoinSymbol })
              "
            />
          </div>
          <div class="val">
            {{ common.cutXiaoNum1(statistics.totalWithdrawAmount) }}
          </div>
        </div>
        <div class="item">
          <div class="name">
            {{ $t("assetbag.desc10", { n: statistics.fillCoinSymbol }) }}
            <tooltip
              :content="
                $t('assetbag.desc106', { n: statistics.fillCoinSymbol })
              "
            />
          </div>
          <div class="val">
            {{ common.cutXiaoNum1(statistics.totalFillAmount) }}
          </div>
        </div>
        <div class="item">
          <div class="name">
            {{ $t("assetbag.desc12") }}
          </div>
          <div class="val view" @click="$emit('incomeRecord')">
            {{ $t("assetbag.desc14") }}
          </div>
        </div>
        <div class="item">
          <div class="name">{{ $t("assetbag.desc13") }}</div>
          <div class="val view" @click="$emit('backfillRecord')">
            {{ $t("assetbag.desc14") }}
          </div>
        </div>
      </div>
      <AccountData
        class="data-right"
        :statistics="statistics"
        :canBackfillAble="canBackfillAble"
        @backfill="$emit('backfill')"
      />
    </div>
  </div>
</template>
<script>
import AccountData from "./AccountData";
export default {
  components: { AccountData },
  props: {
    statistics: Object,
    canBackfillAble: Boolean,
  },
  data() {
    return {
      hide: false,
      DataDom: null,
      domHeight: "auto",
    };
  },
  mounted() {
    if (Number(localStorage.getItem("ismobile")) === 1) {
      this.$nextTick(() => {
        this.domHeight = `${this.$refs.DataDom.offsetHeight}px`;
      });
    }
  },
};
</script>
<style lang="less" scoped>
.data-box {
  margin-top: 24px;
  margin-bottom: 16px;
  &-title {
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
    transition: 0.5s;
    .left {
      color: #fff;
      //font-family: Bai Jamjuree;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 1em;
    }
    .right {
      display: none;
      .btn {
        padding-right: 18px;
        position: relative;
        color: #a0a0a0;
        text-align: right;
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        transition: 0.5s;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 12px;
          height: 12px;
          background: url("../../../../public/images/new/upicon.png") no-repeat
            center;
          background-size: contain;
          transition: 0.5s;
        }
        &.hide {
          &::after {
            transform: translateY(-50%) rotate(180deg);
          }
        }
      }
    }
  }
}

.withdraw-data-min {
  display: none;
}
.item {
  margin-top: 28px;
  padding-right: 10px;
  box-sizing: border-box;
  &:last-child,
  &:nth-child(4) {
    padding-right: 0;
  }
  .name {
    min-height: 1.3em;
    color: #a8a8a8;
    //font-family: Bai Jamjuree;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3em;
    text-transform: capitalize;
    word-break: break-all;
  }
  .val {
    margin-top: 6px;
    min-height: 1.2em;
    color: #fff;
    //font-family: Bai Jamjuree;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 1.2em;
    &.view {
      color: #c9fa5b;
      cursor: pointer;
    }
  }
}
.data-overview {
  display: flex;
  overflow: hidden;
  height: auto;
  transition: 0.5s;

  .data-left {
    border-radius: 12px;
    background: #181818;
    padding: 2px 32px 32px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    flex: 1;
    min-width: 0;
    .item {
      width: 25%;
    }
  }
}
@media (max-width: 1200px) {
  .data-overview {
    .data-left {
      padding: 2px 24px 24px;
    }
  }
}
@media (max-width: 992px) {
  .data-overview {
    .data-left {
      .item {
        width: 33%;
      }
    }
  }
}
@media (max-width: 767px) {
  .withdraw-data-min {
    display: flex;
  }

  .data-box {
    &-title {
      .left {
        font-size: 16px;
      }
      .right {
        display: block;
      }
    }
  }
  .data-overview {
    .data-left {
      padding: 2px 20px 20px;
      border-radius: 10px;
      .item {
        width: 50%;
      }
    }
    .data-right {
      display: none;
    }
    .item {
      margin-top: 18px;
      .name {
        font-size: 12px;
      }
      .val {
        min-height: 1.3em;
        line-height: 1.3em;
        font-size: 14px;
      }
    }
  }
}
</style>
