<template>
  <div class="owns">
    <div class="item-con" v-for="item of list" :key="item.id">
      <hoverborder class="item">
        <div class="status-con">
          <div class="status" v-if="item.status === 2">
            <img
              src="../../../../public/images/asset/assetbag/bag_finish.png"
            />
            <span>{{ $t("assetbag.desc85") }}</span>
          </div>
          <template v-else>
            <div class="status" v-if="item.backfillStatus === 1">
              <img
                src="../../../../public/images/asset/assetbag/backfill_finish.png"
              />
              <span>{{ $t("assetbag.desc156") }}</span>
            </div>
            <div class="status" v-if="item.backfillStatus === 0">
              <img
                src="../../../../public/images/asset/assetbag/backfill_underway.png"
              />
              <span>{{ $t("assetbag.desc84") }}</span>
            </div>
          </template>
        </div>
        <div class="item-box">
          <div class="item-left">
            <div class="title">{{ item.name }}</div>
            <div class="img-con">
              <comimage class="img" :src="item.img" />
            </div>
          </div>
          <div class="info">
            <div class="title">{{ item.name }}</div>
            <div class="info-text">
              <div class="text-item">
                <div class="name">
                  {{ $t("assetbag.desc15") }}
                  <!-- <tooltip
                    :content="
                      $t('assetbag.desc107', { n: item.releaseCoinName })
                    "
                  /> -->
                </div>
                <div class="val">
                  {{ item.releaseAmount }} {{ item.releaseCoinName }}
                </div>
              </div>
              <div class="text-item">
                <div class="name">
                  {{ $t("assetbag.desc24") }}
                  <tooltip
                    :content="$t('assetbag.desc113', { n: item.fillCoinName })"
                  />
                </div>
                <div class="val">
                  {{ item.fillAmount }} {{ item.fillCoinName }}
                </div>
              </div>
              <div class="text-item">
                <div class="name">
                  {{ $t("assetbag.desc25") }}
                  <tooltip
                    :content="$t('assetbag.desc114', { n: item.fillCoinName })"
                  />
                </div>
                <div class="val">
                  {{
                    common.numToFixed(
                      common.getValueMultip(item.fillRate, 100),
                      2
                    )
                  }}%
                </div>
              </div>
              <div class="text-item">
                <div class="name">
                  {{ $t("assetbag.desc26") }}
                  <tooltip :content="$t('assetbag.desc115')" />
                </div>
                <div class="val">
                  {{
                    item.releaseRate
                      ? common.getValueMultip(item.releaseRate, 100)
                      : 0
                  }}%
                </div>
              </div>
              <div class="text-item">
                <div class="name">
                  {{ $t("assetbag.desc30", { n: item.releaseCoinName }) }}
                  <tooltip
                    :content="
                      $t('assetbag.desc116', { n: item.releaseCoinName })
                    "
                  />
                </div>
                <div class="val">{{ item.withdrawAmount }}</div>
              </div>
              <div class="text-item">
                <div class="name">
                  {{ $t("assetbag.desc31") }}
                  <tooltip
                    :content="
                      $t('assetbag.desc117', { n: item.releaseCoinName })
                    "
                  />
                </div>
                <div class="val">{{ item.releasedAmount }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-con">
          <div
            class="btn on"
            :class="{
              disable: item.backfillStatus === 1,
            }"
            @click="handleBackfill(item)"
          >
            {{ $t("assetbag.desc27") }}
          </div>
          <div
            class="btn"
            :class="{
              disable:
                common.getValueMultip(item.fillRate, 100) < 30 ||
                Number(item.releasedAmount) === 0,
            }"
            @click="handleWithdraw(item)"
          >
            {{ $t("assetbag.desc28") }}
          </div>
          <div class="btn" @click="$emit('detail', item.id)">
            {{ $t("assetbag.desc29") }}
          </div>
        </div>
      </hoverborder>
    </div>
    <div class="no-data" v-if="list.length === 0">
      <img src="../../../../public/images/no-data.png" alt="" />
      <span>{{ $t("record.desc13") }}</span>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    list: Array,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(["language"]),
  },
  mounted() {},
  methods: {
    handleBackfill(item) {
      if (item.backfillStatus === 1) {
        this.$message(this.$t("assetbag.desc24"));
        return;
      }
      this.$emit("backfill", item.id);
    },

    handleWithdraw(item) {
      if (this.common.getValueMultip(item.fillRate, 100) < 30) {
        this.$message(this.$t("assetbag.desc87", { n: "30%" }));
        return;
      } else if (Number(item.releasedAmount) === 0) {
        this.$message(this.$t("assetbag.desc124"));
        return;
      }

      this.$emit("withdraw", item);
    },
  },
};
</script>
<style lang="less" scoped>
.no-data {
  width: 100%;
  padding: 50px 0;

  img {
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto 16px;
  }

  span {
    display: block;
    margin: 0 auto;
    //font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a1a1a1;
  }
}
.owns {
  display: flex;
  flex-wrap: wrap;
  .item-con {
    width: 33.33%;
    padding: 0 7px 14px;
    box-sizing: border-box;
  }
  .item {
    height: 100%;
    border-radius: 12px;
    padding: 18px;
    box-sizing: border-box;
    background: #181818;
    position: relative;
    overflow: hidden;
    &:hover {
      .btn-con {
        opacity: 1;
        transform: none;
      }
      .info {
        transform: translateY(-46px);
        .title {
          opacity: 0;
        }
      }
    }

    .item-box {
      display: flex;
      position: relative;
      z-index: 1;
    }
    .title {
      min-height: 20px;
      color: #fff;
      //font-family: Bai Jamjuree;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    .btn-con {
      width: 100%;
      left: 0;
      position: absolute;
      transform: translateY(100%);
      bottom: 18px;
      padding-top: 10px;
      display: flex;
      justify-content: space-between;
      transition: 0.5s;
      padding: 0 24px;
      box-sizing: border-box;
      z-index: 2;
      opacity: 0;
    }
    .btn {
      margin-top: 10px;
      flex: 1;
      max-width: 96px;
      min-width: 80px;
      height: 40px;
      font-size: 14px;
      color: #fff;
      font-weight: 500;
      box-sizing: border-box;
      //font-family: Bai Jamjuree;
      cursor: pointer;
      background: url("../../../../public/images/asset/assetbag/btn2_bg.png")
        center no-repeat;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      &.on {
        color: #000;
        background-image: url("../../../../public/images/asset/assetbag/btn3_bg.png");
      }
      &.disable {
        color: #000;
        background-image: url("../../../../public/images/asset/assetbag/btn_disable_small.png");
      }
    }
    .btn-min {
      display: none;
    }
    .item-left {
      display: flex;
      align-items: center;
      .title {
        display: none;
      }
    }
    .status-con {
      position: absolute;
      top: 10px;
      right: 0;
      width: 78px;
      height: 64px;
      .status {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        span {
          color: #fff;
          //font-family: Poppins;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 12px;
          text-transform: capitalize;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(-9.101deg);
          white-space: nowrap;
        }
      }
    }
    .img-con {
      .img {
        width: 120px;
        height: 120px;
        object-fit: contain;
      }
    }
    .info {
      margin-left: 18px;
      min-width: 0;
      flex: 1;
      transition: 0.5s;
      .title {
        margin-bottom: 20px;
        transition: 0.5s;
      }
      .info-text {
        display: flex;
        flex-wrap: wrap;
        .text-item {
          padding-right: 5px;
          margin-bottom: 16px;
          width: 50%;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
          transition: 0.5s;
          &:nth-child(even) {
            padding-right: 0;
          }
          &:last-child,
          &:nth-last-child(2) {
            margin-bottom: 0;
          }

          .name {
            color: #a8a8a8;
            //font-family: Bai Jamjuree;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            text-transform: capitalize;
          }
          .val {
            margin-top: 8px;
            min-height: 14px;
            color: #fff;
            //font-family: Bai Jamjuree;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
          }
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .owns {
    .item-con {
      width: 50%;
    }
  }
}

@media (max-width: 992px) {
  .owns {
    .item {
      padding: 20px;
    }
  }
}
@media (max-width: 767px) {
  .no-data {
    width: 100%;
    padding: 60px 0;

    img {
      display: block;
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }

    span {
      display: block;
      margin: 0 auto;
      //font-family: "PingFang SC";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 10px;
      text-align: center;
      color: #a1a1a1;
    }
  }

  .owns {
    flex-direction: column;
    .item-con {
      margin-top: 10px;
      margin-left: 0;
      width: 100%;
    }
    .item {
      padding: 15px;
      border-radius: 10px;
      overflow: visible;
      &:hover {
        .btn-con {
        }
        .info {
          transform: none;

          .btn {
            transform: none;
          }
        }
      }
      .status-con {
        top: -24px;
        right: -8px;
      }
      .item-box {
        flex-direction: row;
        padding-left: 85px;
        position: relative;
        min-height: 120px;
      }
      .btn-con {
        transform: none;
        position: static;
        padding: 0;
        opacity: 1;
      }
      .btn {
        max-width: 90px;
        height: 36px;
      }
      .img-con {
        width: 100%;
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: -10px;
        .img {
          width: 90px;
          height: 90px;
        }
      }
      .btn-min {
        display: flex;
        background-image: url("../../../../public/images/asset/assetbag/btn1_bg_min.png");
      }
      .item-left {
        position: absolute;
        top: 0;
        left: 0;
        width: 85px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .title {
          display: block;
          width: 100%;
        }
      }
      .info {
        align-self: flex-start;
        margin-left: 0;
        .title {
          display: none;
        }
        .btn {
          display: none;
        }
      }
    }
  }
}
</style>
