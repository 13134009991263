<template>
  <el-dialog v-model="_show">
    <div class="popup-con">
      <div class="head">
        <div class="title">{{ $t("assetbag.desc65") }}</div>
        <div class="close" @click="_show = false" />
      </div>
      <div class="popup-scroll">
        <div class="con">
          <div class="list">
            <div class="item">
              <div class="name">{{ $t("assetbag.desc66") }}</div>
              <div class="val">{{ $t("assetbag.desc123") }}</div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc67") }}</div>
              <div class="val">{{ info.releasedAmount }}</div>
            </div>
            <div class="item">
              <div class="name">{{ $t("assetbag.desc68") }}</div>
              <div class="val">{{ info.releaseCoinName }}</div>
            </div>
          </div>
        </div>
        <div class="foot">
          <div class="btn-con">
            <div class="btn cancel" @click="_show = false">
              {{ $t("assetbag.desc42") }}
            </div>
            <div class="btn confirm" @click="handleConfirm">
              {{ $t("assetbag.desc69") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
export default {
  emits: ["update:show"],
  props: {
    show: Boolean,
    info: Object,
  },
  data() {
    return {};
  },
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(e) {
        this.$emit("update:show", e);
      },
    },
  },
  methods: {
    handleConfirm() {
      this.$post(this.URL.assetbag.withdraw, {
        id: this.info.id,
      }).then((res) => {
        if (res.code !== 0) {
          this.$message(res.message);
          return;
        }
        this._show = false;
        this.$emit("succ");
      });
    },
  },
};
</script>
<style lang="less" scoped>
.popup-con {
  width: 520px;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 12px;
  background: #181818;
  padding: 40px 0;
  box-sizing: border-box;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  .popup-scroll {
    padding: 0 40px;
    max-height: 85vh;
  }
  .head {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    box-sizing: border-box;

    .title {
      min-width: 0;
      flex: 1;
      color: #fff;
      //font-family: Bai Jamjuree;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .close {
      margin-left: 10px;
      flex: 0 0 auto;
      width: 24px;
      height: 24px;
      background: url("../../../../public/images/icon_close.png") no-repeat
        center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
  .con {
    .list {
      border-radius: 8px;
      background: #242424;
      display: flex;
      flex-direction: column;
      padding: 20px;
      box-sizing: border-box;
    }
    .item {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      &:first-child {
        margin-top: 0;
      }
      .name {
        margin-right: 10px;
        color: rgba(255, 255, 255, 0.6);
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        text-transform: capitalize;
      }
      .val {
        min-width: 0;
        flex: 1;
        color: #fff;
        text-align: right;
        //font-family: Bai Jamjuree;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 14px;
        text-transform: capitalize;
        text-align: right;
      }
    }
  }
  .foot {
    .btn-con {
      margin-top: 32px;
      display: flex;
      justify-content: space-between;
      .btn {
        width: 210px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
        text-align: center;
        //font-family: Bai Jamjuree;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-transform: capitalize;
        cursor: pointer;
        &.cancel {
          color: #fff;
          background-image: url("../../../../public/images/asset/assetbag/pupup_btn1_bg.png");
        }
        &.confirm {
          color: #000;
          background-image: url("../../../../public/images/asset/assetbag/pupup_btn2_bg.png");
        }
      }
    }
    .tip {
      margin-top: 20px;
      color: rgba(255, 255, 255, 0.8);
      //font-family: Bai Jamjuree;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 13px;
      text-transform: capitalize;
    }
  }
}
@media (max-width: 767px) {
  .popup-con {
    width: 100%;
    padding: 20px 0;
    .popup-scroll {
      padding: 0 20px;
    }
    .head {
      margin-bottom: 20px;
      padding: 0 20px;
      .title {
        font-size: 18px;
      }
    }
    .con {
      .list {
        border-radius: 6px;
        padding: 16px;
      }
      .item {
        margin-top: 16px;
        .name,
        .val {
          font-size: 13px;
        }
        .val {
          &.balance {
            font-size: 13px;
          }
        }
      }
    }
    .foot {
      .btn-con {
        margin-top: 24px;
        .btn {
          max-width: 48%;
          width: 137px;
          height: 48px;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
