<template>
  <el-dialog v-model="_show">
    <div class="popup-con">
      <div class="head">
        <div class="title">{{ $t("assetbag.desc57") }}</div>
        <div class="close" @click="_show = false" />
      </div>
      <div class="tabs-con">
        <van-tabs>
          <van-tab :title="$t('assetbag.desc128')">
            <Progress :info="info" />
          </van-tab>
          <van-tab :title="$t('assetbag.desc127')">
            <div class="popup-scroll">
              <div class="con">
                <DetailMsg :info="info" />
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import DetailMsg from "./components/DetailMsg";
import Progress from "./components/Progress";
export default {
  components: {
    DetailMsg,
    Progress,
  },
  emits: ["update:show"],
  props: {
    show: Boolean,
    info: Object,
  },
  computed: {
    _show: {
      get() {
        return this.show;
      },
      set(e) {
        this.$emit("update:show", e);
      },
    },
  },
};
</script>
<style lang="less" scoped>
:deep {
  .van-tabs__nav {
    background: transparent;
  }
  .van-tabs__wrap {
    margin-bottom: 20px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.2);
    }
  }
  .van-tab {
    margin-right: 36px;
    flex: 0 0 auto;
    color: rgba(255, 255, 255, 0.6);
    //font-family: Bai Jamjuree;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 0;
    text-transform: capitalize;
    position: relative;
    transition: 0.5s;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: transparent;
      transition: 0.5s;
    }
    &:last-child {
      margin-right: 0;
    }
  }
  .van-tab--active {
    color: #fff;
    &::after {
      background: #c9fa5b;
    }
  }
  .van-tabs__line {
    display: none;
    background: #c9fa5b;
  }
}
.popup-con {
  width: 520px;

  border-radius: 12px;
  background: #181818;
  padding: 40px 0;
  box-sizing: border-box;
  .tabs-con {
    padding: 0 40px;
  }
  .popup-scroll {
    max-height: 85vh;
    overflow-y: auto;
  }

  .head {
    margin-bottom: 10px;
    padding: 0 40px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    .title {
      min-width: 0;
      flex: 1;
      color: #fff;
      //font-family: Bai Jamjuree;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .close {
      margin-left: 10px;
      flex: 0 0 auto;
      width: 24px;
      height: 24px;
      background: url("../../../../../public/images/icon_close.png") no-repeat
        center;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
  :deep {
    .van-tab {
      margin-right: 32px;
      font-size: 14px;
    }
    .van-tabs__wrap {
      margin-bottom: 16px;
    }
  }
  .popup-con {
    width: 100%;
    padding: 20px 0;

    .tabs-con {
      padding: 0 20px;
    }
    .head {
      margin-bottom: 5px;
      padding: 0 20px;
      .title {
        font-size: 18px;
      }
    }
    .con {
      .list {
        border-radius: 6px;
        padding: 16px;
      }
      .item {
        margin-top: 16px;
        .name,
        .val {
          font-size: 13px;
        }
        .val {
          &.balance {
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>
