<template>
  <div class="asset-bag">
    <DataOverview
      :statistics="statisticsInfo"
      :canBackfillAble="canBackfillAble"
      @incomeRecord="handleIncomeRecordShow"
      @backfillRecord="handleBackfillRecordShow"
      @withdraw="handleWithdrawShow"
      @backfill="handleImmediatelyBackfill"
    />

    <div class="page-box">
      <div class="page-box-title">
        <div class="left">
          {{ $t("assetbag.desc3")
          }}<tooltip :content="$t('assetbag.desc154')" type="title" />
        </div>
      </div>
      <BagType
        :list="bagList"
        :isMobile="isMobile"
        :backfilling="backfilling"
        @buy="getBuyBag"
      />
    </div>
    <div class="page-box">
      <div class="page-box-title">
        <div class="left">
          {{ $t("assetbag.desc4")
          }}<img
            class="isSai"
            src="../../../public/images/asset/assetbag/isSai.png"
            v-if="isSai"
          /><tooltip :content="$t('assetbag.desc154')" type="title" />
        </div>
      </div>
      <OwnBag
        :list="ownList"
        @backfill="getBackfill"
        @detail="getBagDetail"
        @withdraw="handleWithdrawShow"
      />
    </div>
    <BuyBagPopup v-model:show="buyBagShow" :info="buyInfo" @succ="handleInit" />
    <BackfillBagPopup
      v-model:show="backfillBagShow"
      :info="backfillInfo"
      @succ="handleInit"
    />
    <BagDetailPopup v-model:show="bagDetailShow" :info="detailInfo" />
    <WithdrawConfirmPopup
      v-model:show="withdrawConfirmShow"
      :info="withdrawInfo"
      @succ="withdrawSuccShow = true"
    />
    <WithdrawSuccPopup
      v-model:show="withdrawSuccShow"
      @record="incomeRecordShow = true"
    />
    <IncomeRecordPopup v-model:show="incomeRecordShow" :isMobile="isMobile" />
    <BackfillRecordPopup
      v-model:show="backfillRecordShow"
      :isMobile="isMobile"
    />
  </div>
</template>
<script>
import DataOverview from "./components/DataOverview";
import BagType from "./components/BagType";
import OwnBag from "./components/OwnBag";
import BuyBagPopup from "./components/BuyBagPopup";
import BackfillBagPopup from "./components/BackfillBagPopup";
import BagDetailPopup from "./components/BagDetailPopup";
import WithdrawConfirmPopup from "./components/WithdrawConfirmPopup";
import WithdrawSuccPopup from "./components/WithdrawSuccPopup";
import IncomeRecordPopup from "./components/IncomeRecordPopup";
import BackfillRecordPopup from "./components/BackfillRecordPopup";
import { mapState } from "vuex";
export default {
  components: {
    DataOverview,
    BagType,
    OwnBag,
    BuyBagPopup,
    BackfillBagPopup,
    BagDetailPopup,
    WithdrawConfirmPopup,
    WithdrawSuccPopup,
    IncomeRecordPopup,
    BackfillRecordPopup,
  },
  data() {
    return {
      isMobile: false,
      buyBagShow: false,
      backfillBagShow: false,
      bagDetailShow: false,
      withdrawConfirmShow: false,
      withdrawSuccShow: false,
      incomeRecordShow: false,
      backfillRecordShow: false,
      bagList: [],
      buyInfo: {},
      buyBagPsdShow: false,
      buyBagLoading: false,
      ownList: [],
      backfillInfo: {},
      detailInfo: {},
      statisticsInfo: {},
      withdrawInfo: {},
    };
  },
  computed: {
    ...mapState(["userData"]),
    backfilling() {
      const info = this.ownList.find((item) => item.backfillStatus === 0);
      if (info) {
        return true;
      } else {
        return false;
      }
    },
    canBackfillAble() {
      if (this.backfilling && Number(this.statisticsInfo.balance) > 0) {
        return true;
      } else {
        return false;
      }
    },
    isSai() {
      try {
        return Boolean(this.userData.isSai);
      } catch (error) {
        return false;
      }
    },
  },
  mounted() {
    if (Number(localStorage.getItem("ismobile")) === 1) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.handleInit();
  },
  methods: {
    async handleInit() {
      try {
        await this.getStatistics();
        await this.getOwnBagList();
        this.handleJudge();
        await this.getBagList();
      } catch (error) {}
    },
    //获取总览数据
    async getStatistics() {
      const res = await this.$post(this.URL.assetbag.statistics, {});
      if (res.code !== 0) return;
      this.statisticsInfo = res.data;
    },
    //获取资金包列表
    async getBagList() {
      const res = await this.$post(this.URL.assetbag.bagList, {});
      if (res.code !== 0) return;
      this.bagList = res.data;
    },
    //获取我的资金包
    async getOwnBagList() {
      const res = await this.$post(this.URL.assetbag.ownBagList, {});
      if (res.code !== 0) return;
      this.ownList = res.data;
    },
    //判断使用
    handleJudge() {
      if (this.canBackfillAble) {
        this.handleImmediatelyBackfill();
      } else if (Number(this.statisticsInfo.balance) > 0) {
        this.$message(this.$t("assetbag.desc125"));
      }
    },
    getBuyBag(id) {
      this.$post(this.URL.assetbag.buyBagView, { id }).then((res) => {
        if (res.code !== 0) return;
        this.buyInfo = res.data;
        this.buyBagShow = true;
      });
    },
    getBackfill(id) {
      this.$post(this.URL.assetbag.backfillBagView, { id }).then((res) => {
        if (res.code !== 0) return;
        this.backfillInfo = res.data;
        this.backfillBagShow = true;
      });
    },
    getBagDetail(id) {
      this.$post(this.URL.assetbag.bagDetail, { id }).then((res) => {
        if (res.code !== 0) return;
        this.detailInfo = res.data;
        this.bagDetailShow = true;
      });
    },
    handleIncomeRecordShow() {
      if (Number(localStorage.getItem("ismobile")) === 1) {
        this.$router.push("/income-record");
        return;
      }
      this.incomeRecordShow = true;
    },
    handleBackfillRecordShow() {
      if (Number(localStorage.getItem("ismobile")) === 1) {
        this.$router.push("/backfill-record");
        return;
      }
      this.backfillRecordShow = true;
    },
    handleWithdrawShow(info) {
      this.withdrawConfirmShow = true;
      this.withdrawInfo = info;
    },
    handleImmediatelyBackfill() {
      const info = this.ownList.find((item) => item.backfillStatus === 0);
      if (info) {
        this.$post(this.URL.assetbag.backfillBagView, { id: info.id }).then(
          (res) => {
            if (res.code !== 0) return;
            this.backfillInfo = res.data;
            this.backfillBagShow = true;
          }
        );
      }
    },
  },
};
</script>
<style lang="less" scoped>
.asset-bag {
  position: relative;
  .page-box {
    margin-top: 24px;
    margin-bottom: 16px;
    &-title {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      .left {
        color: #fff;
        //font-family: Bai Jamjuree;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 1em;
        .isSai {
          margin-left: 10px;
          // width: 60px;
          height: 20px;
          object-fit: contain;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .asset-bag {
    .page-box {
      margin-top: 20px;
      &-title {
        margin-bottom: 12px;
        .left {
          font-size: 16px;
          .isSai {
            margin-left: 8px;
            height: 16px;
          }
        }
      }
    }
  }
}
</style>
